import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/builds/everyone/dcdcweb-saturday/src/components/Layout/index.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="h1" components={components}>{`Media Kit`}</MDXTag>
      <MDXTag name="p" components={components}>{`Coming Soon...`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    